import React, { useContext, useEffect, useRef, useState } from "react";
import { pageDocPath } from "../../../helpers";
import { ArrowLeft } from "../icon/arrow-left";
import PortableText from "../portableText";
import { cn } from "../../lib/helpers";
import { faArrowsSplitUpAndLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ImageBuilder } from "../global/image-builder";
import { useInView } from "react-intersection-observer";
import { SLIDE_SPEED } from "./tabbed";
import { SiteContext } from "../global/site-context";

import * as styles from "../portableText.module.css";
import * as globalStyles from "../global/global.module.css";

// @todo avoid duplicate code in: carousel-slides.js, tabbed.js, signal-sources.js, card-carousel.js

export const CarouselSlides = ({ section }) => {
  const site = useContext(SiteContext);
  const slides = section.content;
  const [active, setActive] = useState(0);
  const [isAutoplayed, setIsAutoplayed] = useState(false);
  const autoPlayRef = useRef();

  const { ref, inView } = useInView({
    initialInView: false,
    triggerOnce: true,
    onChange: (inView) => {
      if (inView && site.isNotMobile) {
        setIsAutoplayed(true);
      }
    },
  });

  useEffect(() => {
    clearInterval(autoPlayRef.current);
    if (inView && isAutoplayed) {
      autoPlayRef.current = setInterval(() => {
        setActive((oldActive) =>
          oldActive < slides.length - 1 ? oldActive + 1 : 0
        );
      }, SLIDE_SPEED);
    }

    return () => clearInterval(autoPlayRef.current);
  }, [isAutoplayed, inView]);

  return (
    <>
      {slides.length > 0 && (
        <>
          <div ref={ref}></div>
          <ul className="flex items-center flex-wrap border-b border-dark-10 mb-12">
            {slides.map((s, i) => (
              <li key={i}>
                <a
                  role="button"
                  className={cn(
                    "group cursor-pointer inline-block py-3 md:py-4 pr-3 md:text-xl font-semibold leading-tight border-b-2 transition-nav mr-3 md:mr-0",
                    i !== 0 ? "md:pl-3" : null,
                    i === active
                      ? "text-dark-90 border-black"
                      : "text-link border-transparent hover:border-link"
                  )}
                  onClick={(e) => {
                    setActive(i);
                    setIsAutoplayed(false);
                    e.preventDefault();
                  }}
                >
                  {s.header}
                </a>
              </li>
            ))}

            <li className="hidden sm:flex ml-4 pb-1 flex-grow justify-end">
              <a
                onClick={() => {
                  setIsAutoplayed(!isAutoplayed);
                }}
                className={cn("group relative", globalStyles.carouselButton)}
                role="button"
                aria-label="autoplay"
              >
                <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute top-0 right-0 bottom-0 left-0 bg-dark-10 rounded-lg"></div>
                <img
                  src={`/static/animation/circular-progress.${
                    isAutoplayed ? "gif" : "png"
                  }`}
                  width={20}
                />
              </a>
            </li>
          </ul>
          <div className="grid md:grid-cols-1/3-2/3 gap-12 mb-12">
            <PortableText
              blocks={slides[active]?._rawContent}
              className={styles.checkList}
            />
            <div className="flex justify-end">
              <a href={slides[active]?.link}>
                <ImageBuilder
                  image={slides[active]?.mainImage}
                  width={606}
                  alt={`Product features for ${slides[active]?.header}`}
                />
              </a>
            </div>
          </div>
        </>
      )}
      {slides[active]?.contentRefs.length > 0 ? (
        <Playbooks {...slides[active]} />
      ) : section.contentRefs?.length > 0 ? (
        <Playbooks {...section} />
      ) : null}
    </>
  );
};

const Playbooks = (props) => (
  <>
    {props.header && (
      <h4 className="font-semibold text-lg mb-6">{props.header} playbooks</h4>
    )}
    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-6 pb-2">
      {props.contentRefs
        ?.filter((p) => p.slug?.current)
        .map((p, i) => (
          <PlaybookTile key={i} doc={p} />
        ))}
    </div>
  </>
);

const PlaybookTile = ({ doc }) => (
  <a
    className="relative flex bg-white text-secondary transform transition-card scale-100 hover:scale-105 p-4 pl-3 rounded-lg border border-dark-10 font-medium pb-12"
    href={pageDocPath(doc)}
  >
    <FontAwesomeIcon
      icon={faArrowsSplitUpAndLeft}
      className="text-dark-70 mt-0.5 mr-1.5"
    />
    <div className="leading-tight">
      {doc.title}
      <p className="text-link text-xs absolute bottom-4">
        See playbook <ArrowLeft rotate={180} />
      </p>
    </div>
  </a>
);
