import React from "react";
import { CardContext } from "../card/context";
import { Card } from "../card/card";
import Masonry from "react-masonry-css";
import { cn } from "../../lib/helpers";
import { Button } from "../button";

import * as styles from "../global/global.module.css";
import { ArrowRight } from "../icon/arrow-left";

const STORY_CTA_TEXT = "Read the story";

export const ReferenceList = ({ section }) => (
  <>
    <div
      className="relative"
      style={
        section.layout === "fadeOut"
          ? { maxHeight: "40rem", overflow: "hidden" }
          : null
      }
    >
      <Masonry
        breakpointCols={{
          default: 3,
          1020: 2,
          600: 1,
        }}
        className={styles.masonryGrid}
        columnClassName={styles.masonryGridColumn}
      >
        {section.list.map((doc, i) => (
          <CardContext.Provider
            key={i}
            value={{
              ...doc,
              title: false,
              quote: doc._type === "quotable" ? doc : doc.quote,
              hideImage: true,
              showSummary: true,
              showByline: false,
              maxColumns: 3,
              // showExtended: true,
              // showSubTags: true,
              hideLabels: true,
              hideType: true,
              hideDate: true,
              cta: doc._type === "quotable" ? null : STORY_CTA_TEXT,
              showLogo: true,
              isTall: true,
              isMasonry: true,
              skipLink: doc._type === "quotable",
            }}
          >
            <Card
              className={cn(
                "border border-dark-10",
                doc._type === "quotable"
                  ? null
                  : "hover:shadow-lg hover:border-dark-20"
              )}
            />
          </CardContext.Provider>
        ))}
      </Masonry>
      {section.layout === "fadeOut" && (
        <div className="absolute right-0 left-0 bottom-0 h-24 bg-gradient-to-t from-purple-ultralight to-transparent"></div>
      )}
    </div>
    {section.cta && (
      <div className="text-center mt-12">
        <Button
          href={section.ctaPath || "/resources"}
          text={section.cta}
          bgColor="bg-black"
          icon={<ArrowRight size="12" />}
          iconRight={true}
          className="min-w-64"
        />
      </div>
    )}
  </>
);
