import { graphql } from "gatsby";
import { Page } from "../components/page";
import React from "react";
import GraphQLErrorList from "../graphql/graphql-error-list";
import { LayoutContainer } from "../containers/layout";
import Container from "../components/global/container";
import PageHeadMeta from "../components/global/page-head-meta";
import { toPlainText } from "../lib/helpers";

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      _type
      id
      _id
      hideTitle
      mainImage {
        ...SanityImage
        alt
      }
      title
      slug {
        current
      }
      status
      contentType
      hideNav
      pageWidth
      backgroundColor
      backgroundImage {
        ...SanityImageSimple
      }
      categories {
        _id
        title
        titlePublic
      }
      companies {
        _id
      }
      sections {
        ... on SanityPageComponent {
          _type
          name
          anchor
          header
          subheader
          _rawSummary(resolveReferences: { maxDepth: 5 })
          layout
          background
          padding
          container
          mainImage {
            ...SanityImageSimple
          }
          image {
            ...SanityImage
            alt
            caption
          }
          category
          effect
          cards
          types
          contentRefs {
            ... on SanityPlaybook {
              _id
              _type
              title
              slug {
                current
              }
              _rawExcerpt(resolveReferences: { maxDepth: 5 })
              authors {
                _key
                author {
                  ...SanityAuthor
                }
              }
            }
            ... on SanityQuotable {
              ...Quotable
            }
          }
          #columns
        }
        ... on SanityPageSectionSimple {
          _type
          _rawContent(resolveReferences: { maxDepth: 5 })
          anchor
          header
          subheader
          _rawSummary(resolveReferences: { maxDepth: 5 })
          layout
          background
          padding
          container
          mainImage {
            ...SanityImageSimple
          }
          image {
            ...SanityImage
            alt
          }
          tableOfContents
          rounded
          constrainImages
        }
        ... on SanityPageSectionColumns {
          _type
          content {
            header
            mainImage {
              ...SanityImageSimple
            }
            _rawContent(resolveReferences: { maxDepth: 5 })
            author {
              ...SanityAuthor
            }
            layout
            icon
          }
          maxColumns
          anchor
          header
          subheader
          _rawSummary(resolveReferences: { maxDepth: 5 })
          layout
          background
          padding
          style
          container
          image {
            ...SanityImage
          }
        }
        ... on SanityWebForm {
          _type
          anchor
          header
          subheader
          _rawSummary(resolveReferences: { maxDepth: 5 })
          background
          padding
          container
          campaign
          campaignType
          lists
          sendgridListsOptional
          alertEmail
          buttonText
          formFieldOpenTextPlaceholder
          _rawSuccess(resolveReferences: { maxDepth: 5 })
        }
        ... on SanityWebFormHubspot {
          _type
          anchor
          header
          subheader
          _rawSummary(resolveReferences: { maxDepth: 5 })
          background
          padding
          container
          layout
          formId
          buttonText
          redirectUrl
        }
        ... on SanityEmbedSection {
          _type
          embedId
          type
          header
          _rawSummary(resolveReferences: { maxDepth: 5 })
          padding
        }
        ... on SanityPageSectionSlides {
          _type
          anchor
          header
          subheader
          _rawSummary(resolveReferences: { maxDepth: 5 })
          background
          padding
          container
          mainImage {
            ...SanityImageSimple
          }
          image {
            ...SanityImage
            alt
          }
          category
          contentRefs {
            ... on SanityPlaybook {
              _id
              _type
              title
              slug {
                current
              }
            }
            ... on SanityQuotable {
              ...Quotable
            }
          }
          content {
            header
            mainImage {
              ...SanityImageSimple
            }
            mainVideo {
              asset {
                url
              }
            }
            _rawContent(resolveReferences: { maxDepth: 5 })
            author {
              ...SanityAuthor
            }
            layout
            contentRefs {
              _id
              _type
              title
              slug {
                current
              }
            }
            link
          }
        }
        ... on SanityPageSectionList {
          _type
          anchor
          header
          _rawSummary(resolveReferences: { maxDepth: 5 })
          background
          padding
          container
          layout
          ctaPath
          cta
          list {
            ... on SanityStory {
              ...cardNodeStory
            }
            ... on SanityQuotable {
              ...Quotable
            }
            ... on SanityPost {
              ...cardNode
            }
            ... on SanityPlaybook {
              ...cardNodePlaybook
            }
          }
        }
        ... on SanityPageSectionComponentList {
          _type
          maxColumns
          anchor
          layout
          padding
          container
          content {
            content {
              ... on SanityPageSectionSimple {
                _type
                _rawContent(resolveReferences: { maxDepth: 5 })
                header
                subheader
                _rawSummary(resolveReferences: { maxDepth: 5 })
                layout
                padding
                container
                anchor
                background
                tableOfContents
                image {
                  ...SanityImage
                  alt
                  link
                }
              }
              ... on SanityWebFormHubspot {
                _type
                anchor
                header
                subheader
                _rawSummary(resolveReferences: { maxDepth: 5 })
                background
                padding
                container
                layout
                formId
                buttonText
                redirectUrl
              }
              ... on SanityEmbedSection {
                _type
                embedId
                type
                header
                _rawSummary(resolveReferences: { maxDepth: 5 })
                padding
              }
              ... on SanityPageComponent {
                _type
                name
                anchor
                header
                subheader
                _rawSummary(resolveReferences: { maxDepth: 5 })
                layout
                background
                padding
                container
                mainImage {
                  ...SanityImageSimple
                }
                image {
                  ...SanityImage
                  alt
                }
                cards
              }
              ... on SanityPageSectionColumns {
                _type
                content {
                  header
                  mainImage {
                    ...SanityImageSimple
                  }
                  _rawContent(resolveReferences: { maxDepth: 5 })
                  author {
                    ...SanityAuthor
                  }
                  layout
                  icon
                }
                maxColumns
                anchor
                header
                subheader
                _rawSummary(resolveReferences: { maxDepth: 5 })
                layout
                background
                padding
                style
                container
              }
            }
          }
        }
      }
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      relatedPosts {
        ...cardNode
      }
      relatedAssets {
        ...cardNodeAsset
      }
      relatedPlaybooks {
        ...cardNodePlaybook
      }
    }
  }
`;

const PageTemplate = (props) => {
  const { data, errors } = props;
  const page = data && data.page;

  // @todo move the exception for uncommon landing to url generation not meta data
  // see: gatsyby-node.js
  const isHomepage = ["/", "new"].includes(page?.slug?.current);

  return (
    <LayoutContainer
      // @todo center the logo and fix spacing
      hideNav={page?.hideNav}
      doc={page}
      isHomepage={isHomepage}
      location={props.location}
    >
      {errors && <PageHeadMeta title="GraphQL Error" />}
      {page && (
        <PageHeadMeta
          title={page.title || ""}
          isTitleOverriden={isHomepage}
          description={toPlainText(page?._rawExcerpt)}
          image={page?.mainImage}
          doc={page}
        />
      )}
      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      <Page {...page} />
    </LayoutContainer>
  );
};

export default PageTemplate;
